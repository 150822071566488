@import "bootstrap5.min.css";

@font-face {
  font-family: "Raleway";
  src: url('/fonts/Raleway/raleway-extralight.woff2') format('woff2'),
    url('/fonts/Raleway/raleway-extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url('/fonts/Raleway/raleway-light.woff2') format('woff2'),
    url('/fonts/Raleway/raleway-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url('/fonts/Raleway/raleway-regular.woff2') format('woff2'),
    url('/fonts/Raleway/raleway-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url('/fonts/Raleway/raleway-medium.woff2') format('woff2'),
    url('/fonts/Raleway/raleway-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url('/fonts/Raleway/raleway-semibold.woff2') format('woff2'),
    url('/fonts/Raleway/raleway-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url('/fonts/Raleway/raleway-bold.woff2') format('woff2'),
    url('/fonts/Raleway/raleway-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* ----- GENERAL ----- */

body {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #414042;
  overflow-x: hidden;
}

.modal-open {
  overflow: hidden;
}

*:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.navbar-toggler:focus {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

body.user-is-tabbing *:focus {
  outline: 2px solid #0098E9 !important;
}

html {
  scroll-behavior: smooth;
}

p strong,
li strong {
  font-weight: 500;
}

p.small {
  font-size: 16px;
  color: #6D6E71;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.video-wrapper {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  cursor: pointer;

  .play-btn {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 68px;
    height: 48px;

    &__bg {
      transition: all ease-in-out .2s;
      fill-opacity: .8;
      fill: #212121;
    }
  }

  &:hover {
    .play-btn {
      &__bg {
        fill: #E52D27;
        fill-opacity: 1;
      }
    }
  }

  &.hide-btn {
    &.play-btn {
      display: none;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.inp-border {
  border: 1px solid #ced4da !important;

  &:focus {
    border: 1px solid #ced4da !important;
  }
}

.btn-mc-primary {
  font-weight: 400;
  font-size: 1.25rem;
  border: none;
  width: auto;
  min-width: 230px;
  background: linear-gradient(88.99deg, #00569a 0.86%, #207ab6 92.9%);
  border-radius: 30px;
  padding: 8px 24px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0;
  white-space: nowrap;
  transition: all .3s ease-in-out;
}

.btn-mc-primary:hover {
  color: #fff;
  text-decoration: none;
  background: #001C43;
}

.btn-mc-outline-primary {
  white-space: nowrap;
  font-weight: 400;
  font-size: 1.25rem;
  border: none;
  min-width: 230px;
  border-radius: 30px;
  padding: 8px 24px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid #00569a;
  background: #fff;
  color: #00569a;
  transition: all .3s ease-in-out;
}

.btn-mc-outline-primary:hover {
  color: #fff;
  background: #00569a;
  text-decoration: none;
}

.blue-section {
  background: linear-gradient(278.08deg, #0189D4 0.2%, #001C43 100%);
}

.blue-section .h1,
.blue-section .h2,
.blue-section p {
  color: #fff;
}

.blue-section .btn-mc-outline-primary {
  background: transparent;
  color: #fff;
  border-color: #fff;
  width: 306px;
  max-width: 100%;
}

.blue-section .btn-mc-outline-primary:hover {
  background: #fff;
  color: #00569A;
}


.floating-label-input {
  position: relative;
  width: 100%;
  height: 48px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 12px;
  transition: all ease-in-out .15s;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 6000s ease-in-out 0s;
    -webkit-text-fill-color: #6d6e71 !important;
  }

  label {
    position: absolute;
    z-index: 10;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    color: #6D6E71;
    transition: all ease-in-out 0.1s;
  }

  &:focus-within label,
  &.filled label {
    font-size: .75rem;
    opacity: .7;
    top: 12px;
  }

  input {
    position: absolute;
    width: 100%;
    bottom: 0;
    border: 0;
    background: transparent;
    z-index: 11;
    padding: 15px 24px 0 17px;
    height: 48px;
    font-size: 1.25rem;
    color: #6d6e71;
  }

  input:focus {
    border: none;
    outline: none;
  }
}

form {
  label {
    transition: 150ms cubic-bezier(0.4,0,0.2,1);
  }

  .floating-label-input {
    overflow: visible;

    input {
      padding-top: 5px;
    }

    &:focus-within, 
    &.filled {
      border-color: #009788 !important;

      label {
        transform-origin: left;
        transform: translateY(-115%) scale(0.98);
        background-color: #FFFFFF;
        padding: 0 .2em;
        color: #009788;
        opacity: 1;
      }
    }
  }
}

.textarea-wrapper {
  height: unset;
  border: none;

  label {
    top: 24px;
  }

  textarea {
    width: 100%;
    bottom: 0;
    border: 0;
    background: transparent;
    z-index: 2;
    padding: 15px 24px 0 17px;
    min-height: 96px;
    font-size: 1.25rem;
    color: #6d6e71;
    border-radius: 10px;

    &.bordered-textarea,
    &.bordered-textarea:focus {
      border: 1px solid #D1D3D4 !important;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.blue-cta-line {
  position: relative;
  background: #194276;
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 0 25px;
  padding: 22px 47px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.blue-cta-line::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 24px;
  height: 100%;
  background: #F47C20;
}

.blue-cta-line .h2 {
  color: #fff;
}

.blue-cta-line .btn-mc-outline-primary {
  background: transparent;
  color: #fff;
  border-color: #fff;
  width: 306px;
  max-width: 100%;
}

.blue-cta-line .btn-mc-outline-primary:hover {
  background: #fff;
  color: #00569A;
}

@media only screen and (min-width: 992px) {
  .blue-cta-line {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .blue-cta-line .h2 {
    margin-right: 32px;
  }
}

@media only screen and (min-width: 1200px) {

  .blue-cta-line {
    padding-right: 110px;
  }
}

/* ----- HEADER ----- */

.mc-nav {
  background: #fff;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  width: 100%;
  max-width: 1368px;
  margin: 0 auto;
  padding: 20px 0;
  min-height: 96px;
  transition: all ease-in-out .2s;
  z-index: 10000;

  .navbar-brand img {
    width: 150px;
    margin-left: 15px;
  }

  .navbar-toggler {
    svg rect {
      fill: #D1D3D4;
      transition: all ease-in-out .1s;
    }

    &:hover,
    &:focus {
      svg rect {
        fill: #83D2F5 !important;
      }
    }
  }

  .nav-link {
    padding: 8px 15px !important;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 18px;
    color: #414042;
  }

  .navbar-nav {
    margin-top: 20px;
    padding: 8px 0 0;
    border-top: 1px solid #E3E3E3;
  }

  .dropdown-toggle {
    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 4px;
      border: none;
      background: url('/images/svg/header/arrow.svg') no-repeat center/contain;
      transition: all ease-in-out .2s;
    }

    &.show::after {
      transform: scale(1, -1);
    }
  }

  .dropdown-menu {
    height: unset;
    padding: 10px 0;
    background: #fcfcfc;
    box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0;

    .dropdown-item {
      margin: 10px 0;
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 18px;
      color: #414042;
      padding-left: 40px;
      position: relative;
      transition: all 200ms ease-in-out;

      &::before {
        content: "";
        display: block;
        width: 23px;
        height: 23px;
        left: 0;
        top: 4px;
        position: absolute;
        transition: all 250ms ease-in-out;
      }

      &:hover {
        transform: none;
        background: none;
        &::before {
          transform: scale(.89);
        }
      }

      &.crm::before {
        background: url('/images/svg/header/crm.svg') no-repeat center/contain;
      }

      &.bi-tool::before {
        background: url('/images/svg/header/bi-tool.svg') no-repeat center/contain;
      }

      &.sales::before {
        background: url('/images/svg/header/sales.svg') no-repeat center/contain;
      }

      &.marketing::before {
        background: url('/images/svg/header/marketing.svg') no-repeat center/contain;
      }

      &.mobile::before {
        background: url('/images/svg/header/mobile.svg') no-repeat center/contain;
      }

      &.invoice::before {
        background: url('/images/svg/header/invoice.svg') no-repeat center/contain;
      }

      &.helpdesk::before {
        background: url('/images/svg/header/helpdesk.svg') no-repeat center/contain;
      }

      &.project::before {
        background: url('/images/svg/header/project.svg') no-repeat center/contain;
      }

      &.process::before {
        background: url('/images/svg/header/process.svg') no-repeat center/contain;
      }

      &.account::before {
        background: url('/images/svg/header/account.svg') no-repeat center/contain;
      }

      &.task::before {
        background: url('/images/svg/header/task.svg') no-repeat center/contain;
      }

      &.partners::before {
        background: url('/images/svg/header/partners.svg') no-repeat center/contain;
      }

      &.help::before {
        background: url('/images/svg/header/help.svg') no-repeat center/contain;
      }

      &.webinars::before {
        background: url('/images/svg/header/webinars.svg') no-repeat center/contain;
      }

      &.mini-academy::before {
        background: url('/images/svg/header/mini-academy.svg') no-repeat center/contain;
      }

      &.full-service::before {
        background: url('/images/svg/header/full-service.svg') no-repeat center/contain;
      }

      &.intro::before {
        background: url('/images/svg/header/intro.svg') no-repeat center/contain;
      }

      &.blog::before {
        background: url('/images/svg/header/blog.svg') no-repeat center/contain;
      }

      &.hr::before {
        background: url('/images/svg/header/hr.svg') no-repeat center/contain;
      }

      &.events::before {
        background: url('/images/svg/header/events.svg') no-repeat center/contain;
      }

      &.followup::before {
        background: url('/images/svg/header/followup.svg') no-repeat center/contain;
      }
    }

    &.hover--blue {
      .dropdown-item:hover {
        color: #0098E9;
      }
    }
  }

  .btn-success {
    width: calc(100% - 30px);
    max-width: 345px;
    background: #12CA71;
    border-radius: 30px;
    border: none;
    padding: 8px;
    margin: 19px auto 12px;
    display: block;

    &:hover {
      background: #118E52;
    }

    &.registration-btn {
      background: linear-gradient(88.99deg, #00569a 0.86%, #207ab6 92.9%);
      transition: none;

      &:hover {
        color: #fff;
        background: #001C43;
      }
    }
  }
}
.warning-box{
  z-index:2000;
  width:100%;
  text-align:center;
  color:white;
  background-color:red
}
@media only screen and (min-width: 992px) {
  .pt-hero {
    padding-top: 140px !important;
  }

  .sticky-top-hero {
    top: 132px;
    z-index: 999;
  }

  .mc-nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .nav-link {
      padding: 8px 24px !important;
    }

    .navbar-brand {
      margin-right: 45px;
    }

    .nav-item {
      position: relative;
      .nav-link.dropdown-toggle {
        position: relative;

        &::before {
          position: absolute;
          bottom: -32px;
          left: 24px;
          content: '';
          display: block;
          width: 0;
          height: 4px;
          transition: all ease-in-out .2s;
        }

        &::before[aria-expanded="true"] {
          width: calc(100% - 48px);
        }
      }

      &:first-of-type {
        .nav-link.dropdown-toggle {
          &::before[aria-expanded="true"] {
            background: #0098E9;
          }
        }
      }

      &:nth-of-type(2) {
        .nav-link.dropdown-toggle {
          &::before[aria-expanded="true"] {
            background: #FFA057;
          }
        }
      }

      &:nth-of-type(3).active {
        .nav-link::before {
          display: block;
          position: absolute;
          content: '';
          bottom: -32px;
          left: 24px;
          width: calc( 100% - 48px );
          height: 4px;
          background: #009788;
          animation: width .3s ease-in-out;
        }
      }

      &:nth-of-type(4).active {
        .nav-link::before {
          display: block;
          position: absolute;
          content: '';
          bottom: -32px;
          left: 24px;
          width: calc( 100% - 48px );
          height: 4px;
          background: #d1d3d4;
          animation: width .3s ease-in-out;
          width: calc( 100% - 48px );
        }
      }

      @keyframes width {
        0% {
          width: 0;
        }
        100% {
          width: calc( 100% - 48px );
        }
      }
    }

    .navbar-nav {
      margin-top: 0;
      padding: 0;
      border-top: none;
    }

    .dropdown-menu {
      padding: 30px 63px 0;
      top: 92px;
      left: 0;
      background: #fff;
      box-shadow: 0px 14px 20px rgba(81, 81, 81, 0.1);
      border-radius: 0px 0px 10px 10px;
      border: none;
      z-index: -1;

      .dropdown-item {
        margin-bottom: 22px;
        min-height: 110px;

        span {
          font-weight: 300;
          line-height: 18px;
          color: #6D6E71;
          white-space: initial;
          margin-right: -15px;
          text-decoration: none !important;
          display: inline-block !important;
        }
      }
    }

    .btn-success {
      max-width: 165px;
      margin: 0 15px 0 auto;
    }
  }
  .warning-box{
    position:fixed;
  }
}

@media only screen and (min-width: 1200px) {
  .mc-nav {
    .dropdown-menu {
      .dropdown-item {
        min-height: unset;

        span {
          margin-right: -15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1368px) {
  .mc-nav {
    border-radius: 0 0 5px 5px;
  }
}

/* ----- FOOTER ----- */

.mc-footer {
  position: relative;
  z-index: 1;
  padding: 28px 0 8px;
  background: #414042;

  * {
    font-size: 16px !important;
    color: #fff !important;
    line-height: 18px;
  }

  a {
    display: inline-block;
    margin-bottom: 24px;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    margin-bottom: 0;
  }

  &__logo {
    display: block;
    width: 195px;
    margin-bottom: 29px;
  }

  &__separator {
    width: 100%;

    .line {
      height: 1px;
      width: calc(100% - 30px);
      background: rgba(255, 255, 255, 0.4);
    }
  }

  &__copyright {
    margin-top: 8px;
  }

  &__bolder {
    font-weight: 500 !important;
  }

  &__contact li {
    position: relative;
    padding-left: 32px;

    &::before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      left: 0;
      top: 2px;
      position: absolute;
    }

    &.footer-address {
      letter-spacing: -0.0125em;

      &::before {
        background: url('/images/svg/footer-place.svg') no-repeat center/contain;
      }
    }

    &.footer-phone::before {
      background: url('/images/svg/footer-phone.svg') no-repeat center/contain;
    }

    &.footer-email::before {
      background: url('/images/svg/footer-mail.svg') no-repeat center/contain;
    }
  }

  &__languages li a {
    position: relative;
    padding-left: 24px;

    &::before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }

  .flag-hu a::before {
    background: url('/images/svg/footer-flag-hu.svg') no-repeat center/contain;
  }

  .flag-en a::before {
    background: url('/images/svg/footer-flag-en.svg') no-repeat center/contain;
  }

  .flag-ro a::before {
    background: url('/images/svg/footer-flag-ro.svg') no-repeat center/contain;
  }

  &--min {
    padding: 32px 0;

    .mc-footer {
      &__logo {
        width: 255px;
      }

      &__section {
        &:last-of-type {
          ul {
            border-top: 1px solid rgba(255, 255, 255, 0.4);
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
  }
}

.footer-form-v3 {

  .btn-mc-primary,
  .btn-mc-outline-primary {
    width: 354px;
    max-width: 100%;
  }

  .footer-form__form p {
    font-weight: 300;
  }
}

@media only screen and (min-width: 576px) {
  .mc-footer {
    &__address {
      letter-spacing: unset;
    }
  }
}

@media only screen and (min-width: 992px) {
  .mt-lg-90 {
    margin-top: 90px !important;
  }

  .mc-footer {
    padding-bottom: 20px;

    &__separator {
      width: 8.33333333%;
      display: flex;
      justify-content: center;

      .line {
        height: 100%;
        width: 1px;
      }
    }

    &__languages li a {
      margin-bottom: 24px;
    }

    &__copyright {
      margin-top: 90px;
    }

    &--min {
      padding: 32px 0;
      margin-top: auto;

      .mc-footer {
        &__copyright {
          margin-top: 32px;
        }

        &__logo {
          margin-right: 8px;
        }

        &__section {
          display: flex;
          align-items: center;

          &:last-of-type {
            ul {
              border: none;
              margin-left: 8px;
            }
          }

          &:nth-last-of-type(2) {
            border-left: 1px solid rgba(255, 255, 255, 0.4);
            border-right: 1px solid rgba(255, 255, 255, 0.4);

            ul {
              li {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .mc-footer {
    &__section {
      &::after {
        right: 40px;
      }

      &:nth-child(2) {
        &::after {
          left: -32px;
        }
      }
    }

    &--min {
      .mc-footer {
        &__logo {
          margin-right: 68px;
        }

        &__section {
          &:last-of-type {
            ul {
              margin-left: 68px;
            }
          }
        }
      }
    }
  }
}

/* ----- PARTNERS, FOOTER FORM ----- */

.mc-partners {
  display: block;
  padding: 31px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  img {
    display: block;
    max-width: 110px;
    max-height: 24px;
    margin: 0 16px 32px;
    object-fit: contain;
  }
}

.footer-form {
  position: relative;
  background: #003D6C;
  padding: 24px 0 48px;
  color: #414042;

  .h2 {
    font-weight: 200;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;
  }

  &__card {
    width: 488px;
    max-width: 100%;
    position: relative;
    z-index: 2;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 38px 109px rgba(70, 70, 70, 0.07), 0px 18.872px 46.4278px rgba(70, 70, 70, 0.0514715), 0px 7.0983px 26.9951px rgba(70, 70, 70, 0.0328204);
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .h3 {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 30px;
    }

    p {
      font-size: 1rem;
      line-height: 22px;
    }

    img {
      aspect-ratio: 440/280;
    }
  }

  &__wave {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 75%;
    min-width: 100%;
    object-fit: cover;
    filter: drop-shadow(0px -4px 100px rgba(0, 0, 0, 0.25));
  }

  &__form {
    padding: 16px;
    background: #F4F4F4;
    margin: 0 -24px 0;

    p {
      text-align: center;
      font-weight: 500;
      font-size: 0.9375rem;
      line-height: 18px;
      margin: 32px;
    }
  }
}

.footer-form-2 {
  position: relative;
  overflow-x: hidden;

  &__right {
    margin-top: 100px;
    background: #00569A;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 100vw;
    padding: 15px 15px 60px;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    position: relative;

    .h2 {
      position: absolute;
      background: #001C43;
      font-size: 1rem;
      border-radius: 10px 0px 0px 10px;
      line-height: 25px;
      text-align: right;
      color: #FFFFFF;
      display: block;
      width: 305px;
      font-weight: 300;
      padding: 16px;
      right: 0;
      bottom: 33%;
    }

    .inner-wave {
      position: absolute;
      bottom: -1px;
      display: block;
      left: 0;
      right: 0;
      width: 100%;
    }

    .laptop {
      position: absolute;
      top: -100px;
      width: calc(100% - 30px);
      max-width: 540px;
      right: 15px;
    }

    .blobs {
      display: block;
      width: 100%;
    }
  }

  &__wave {
    display: block;
    width: 100%;
    min-width: 100%;
    object-fit: cover;
  }

  &__form {
    padding-bottom: 70px;
  }

  &__title {
    font-weight: 300;
    font-size: 25px;
    line-height: 29px;
    letter-spacing: 0.0025em;
    color: #414042;
    margin-bottom: 32px;
  }

  .floating-label-input {
    border: 1px solid #CDCDCD;
  }
}

@media only screen and (min-width: 768px) {
  .footer-form {
    &__form {
      border-radius: 10px;
      margin: 0 auto 0;

      p {
        text-align: center;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 25px;
      }
    }
  }

  .footer-form-2 {
    margin-top: 64px;
    padding-bottom: 61px;

    &__wave {
      position: absolute;
      bottom: 0;
    }

    &__wide-card {
      position: relative;
      z-index: 2;
      margin: 0 15px;
      background: #fff;
      box-shadow: 0px 38px 109px rgba(70, 70, 70, 0.07), 0px 18.872px 46.4278px rgba(70, 70, 70, 0.0514715), 0px 7.0983px 26.9951px rgba(70, 70, 70, 0.0328204);
      border-radius: 15px;
    }

    &__right {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: unset;
      margin-left: unset;
      margin-right: unset;
      border-radius: 0 15px 15px 0;
      width: 50%;

      .h2 {
        bottom: 32px;
      }

      .laptop {
        top: 50%;
        transform: translateY(-50%);
        max-width: unset;
        right: 32px;
      }

      .inner-wave {
        height: 100%;
        left: 0;
        width: auto;
        bottom: 0;
        top: -1px;
      }
    }

    &__form {
      padding: 32px 0 32px 20px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .mc-partners {
    padding: 71px 0 0;

    img {
      max-width: 225px;
      max-height: 50px;
      margin: 0 0 71px;
    }
  }

  .footer-form {
    padding: 90px 0;

    .h2 {
      font-size: 35px;
      line-height: 41px;
      text-align: center;
    }

    &__card {
      width: 100%;
      height: 100%;

      form {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .floating-label-input {
        margin-bottom: 21px;
      }
    }

    &__form {
      padding: 16px 24px 32px;
    }
  }

  .footer-form-2 {
    margin-top: 38px;
    overflow-x: unset;

    &__wide-card {
      margin: 0 15px;
      background: #fff;
      box-shadow: 0px 38px 109px rgba(70, 70, 70, 0.07), 0px 18.872px 46.4278px rgba(70, 70, 70, 0.0514715), 0px 7.0983px 26.9951px rgba(70, 70, 70, 0.0328204);
      border-radius: 15px;
    }

    &__title {
      font-size: 35px;
      font-weight: 200;
      line-height: 41px;
    }

    &__right {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58.33333333%;
      padding: unset;
      margin-left: unset;
      margin-right: unset;
      border-radius: 0 15px 15px 0;
      position: relative;

      .h2 {
        font-size: 1.25rem;
        width: 432px;
      }

      .blobs {
        max-width: 537px;
      }

      .laptop {
        top: -38px;
        transform: unset;
        max-width: 607px;
        right: 32px;
      }
    }

    .floating-label-input {
      margin-bottom: 27px;
    }
  }
}

@media only screen and (min-width: 1200px) {

  .footer-form__card {
    margin: 0;
  }

  .footer-form-2 {
    margin-top: 48px;

    .laptop {
      top: -48px;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .footer-form-2 {
    margin-top: 48px;

    &__wave {
      transform: scaleY(.75);
      transform-origin: bottom;
    }
  }
}

/* ----- TYPOGRAPHY ----- */

main * {
  color: #414042;
}

.hero .h1,
main h1,
main .h1,
.mc-modal .h1 {
  font-size: 35px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 16px;
}

.hero p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

.hero p.small-hero-p {
  font-size: 20px;
  line-height: 30px;
}

main .h2,
.mc-modal .h2 {
  font-weight: 300;
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 32px;
}

main .h3,
.mc-modal .h3 {
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 32px;
}

main p,
.mc-modal p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

main .p2,
.mc-modal .p2 {
  font-size: 16px;
  line-height: 22px;
}

.card-heading {
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
}

p a,
p a:hover {
  color: #00569A !important;
}

p a:hover {
  text-decoration: underline;
}

main p strong,
main li strong,
.mc-modal p strong {
  font-weight: 500;
}

@media only screen and (min-width: 992px) {

  .hero .h1,
  main h1,
  main .h1,
  .mc-modal .h1 {
    font-weight: 200;
    font-size: 60px;
    line-height: 73px;
    margin-bottom: 64px;
  }

  .hero p {
    font-size: 35px;
    line-height: 48px;
  }

  .hero p.small-hero-p {
    font-size: 20px;
    line-height: 30px;
  }

  main .h2,
  .mc-modal .h2 {
    line-height: 48px;
  }

  main .h3,
  .mc-modal .h3 {
    font-size: 25px;
    line-height: 29px;
  }
}

/* ----- MODAL ----- */

.mc-modal {
  position: fixed;
  z-index: 100;
  padding: 100px 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  &__window {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    margin: auto;
    width: 636px;
    max-width: calc(100% - 24px);
  }

  &__head {
    position: relative;
    background: #001C43;
    padding: 32px 20px;

    .h1 {
      color: #fff;
    }
  }

  &__close-btn {
    background: transparent;
    border: none;
    box-shadow: none;
    position: absolute;
    top: 21px;
    right: 25px;
    display: block;
    width: 14px;
    height: 14px;
    background: url('/images/modal-close-btn.svg') center/contain no-repeat;
  }

  &__body {
    padding: 16px 20px 32px;
  }
}
